var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "top-nav-extension",
      class: { "StarlingSecondaryTransparent80--bg": _vm.isProgress }
    },
    [
      _c(
        "v-layout",
        {
          attrs: { "align-center": "", "justify-center": "", "fill-height": "" }
        },
        [
          _vm.isTabs
            ? _c("top-nav-extension-tabs")
            : _vm.isProgress
            ? _c("top-nav-extension-progress")
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }