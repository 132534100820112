import { render, staticRenderFns } from "./top-nav-extension.vue?vue&type=template&id=20eeb411&"
import script from "./top-nav-extension.vue?vue&type=script&lang=ts&"
export * from "./top-nav-extension.vue?vue&type=script&lang=ts&"
import style0 from "./top-nav-extension.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src233137091/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20eeb411')) {
      api.createRecord('20eeb411', component.options)
    } else {
      api.reload('20eeb411', component.options)
    }
    module.hot.accept("./top-nav-extension.vue?vue&type=template&id=20eeb411&", function () {
      api.rerender('20eeb411', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/navigation/top-nav-extension.vue"
export default component.exports