<template>
  <sm-improved-toolbar class="mobile--toolbar"
             fixed
             height="56px"
             :scroll-off-screen="!isHome"
             :scroll-threshold="200"
             :extended="showExtension"
             :extension-height="extensionHeight">
    <v-layout align-center fill-height px-1>
      <v-flex shrink class="side-area">
        <v-btn flat round @click="goBack" class="ma-0 pa-0" v-show="top.showBackButton">
          <v-icon class="StarlingDarkGrey--text">mdi-chevron-left</v-icon>
        </v-btn>
      </v-flex>

      <v-spacer></v-spacer>

      <v-flex>
        <template v-if="!partner || partner.type === 'BASIC' || partner.type === 'COBRANDED'">
          <v-toolbar-title style="width: 100%; padding:0; margin: 0; overflow: unset; text-overflow: unset;" class="text-center">
            <template v-if="top.logo">
            <div class="d-flex">
              <v-img style="display: inline-block;"
                    class="no-select"
                    :src="'/svg/starling-logo-colors.svg'"
                    contain
                    height="40px"
                    width="90px"/>
              <template v-if="partner && partner.logo && partner.logo.imageUrl">
              <v-divider vertical inset class="mx-1" color="darkgrey" style="
                            margin-top: 0;
                            margin-bottom: 8px !important;
                            min-height: 0;
                            display: inline-block;
                            height: 34px;"/>
              <template v-if="partner.logo.linkUrl">
              <a :href="partner.logo.linkUrl" target="_blank">
                <v-img style="display: inline-block;"
                      class="no-select"
                      v-if="partner && partner.logo && partner.logo.imageUrl"
                      :src="partner.logo.imageUrl"
                      contain
                      height="40px"
                      width="90px"/>
              </a>
              </template>
              <template v-else>
              <v-img style="display: inline-block;"
                    class="no-select"
                    v-if="partner && partner.logo && partner.logo.imageUrl"
                    :src="partner.logo.imageUrl"
                    contain
                    height="40px"
                    width="90px"/>
              </template>
              </template>
              <h3 class="starling-h3 text-wrap">{{ $te(top.title) ? $t(top.title) : top.title }}</h3>
            </div>
            </template>
            <template v-else>
              <h3 class="starling-h3 text-wrap">{{ $te(top.title) ? $t(top.title) : top.title }}</h3>
            </template>
          </v-toolbar-title>
        </template>
        <template v-if="partner && partner.type === 'COLABELLED'">
          <v-toolbar-title style="width: 100%; padding:0; margin: 0; overflow: unset; text-overflow: unset;" class="text-center">
            <template v-if="top.logo">
              <div class="d-flex">
                  <template v-if="partner.logo.linkUrl">
                    <a :href="partner.logo.linkUrl" target="_blank">
                      <v-img style="display: inline-block;"
                            class="no-select"
                            v-if="partner && partner.logo && partner.logo.imageUrl"
                            :src="partner.logo.imageUrl"
                            contain
                            height="40px"
                            width="160px"/>
                    </a>
                  </template>
                  <template v-else>
                    <v-img style="display: inline-block;"
                          class="no-select"
                          v-if="partner && partner.logo && partner.logo.imageUrl"
                          :src="partner.logo.imageUrl"
                          contain
                          height="40px"
                          width="160px"/>
                  </template>
              </div>
            </template>
            <template v-else>
              <h3 class="starling-h3 text-wrap">{{ $te(top.title) ? $t(top.title) : top.title }}</h3>
            </template>
          </v-toolbar-title>
        </template>
      </v-flex>

      <v-spacer></v-spacer>

      <v-flex shrink class="side-area">
      </v-flex>
    </v-layout>

    <template v-slot:extension v-if="showExtension">
      <top-nav-extension></top-nav-extension>
    </template>
  </sm-improved-toolbar>
</template>

<script>
import { mapState } from 'vuex';
import SmImprovedToolbar from '@/views/components/sm-improved-toolbar.vue';
import TopNavExtension from '@/views/components/navigation/top-nav-extension.vue';

export default {
  name: 'navigation-mobile',
  components: { SmImprovedToolbar, TopNavExtension },
  computed: {
    ...mapState('navigation', [ 'top' ]),
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
    extensionTabs() {
      return this.$store.getters['navigation/tabs'];
    },
    extensionProgress() {
      return this.$store.getters['navigation/progress'];
    },
    showExtension() {
      return !!this.extensionTabs || !!this.extensionProgress;
    },
    extensionHeight() {
      if (this.extensionTabs) {
        return '56px';
      } else if (this.extensionProgress) {
        return '30px';
      }
      return undefined;
    },
    isHome() {
      return this.$route.name === 'home';
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style lang="scss">
.mobile--toolbar {
  .side-area {
    min-width: 48px;
  }
  .side-area .v-btn {
    width: 48px;
    height: 48px;
    min-width: 48px;
  }
}
</style>
