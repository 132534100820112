var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "top-nav-extension-progress",
      attrs: {
        xs12: "",
        md8: "",
        "d-flex": "",
        "justify-center": "",
        "align-center": "",
        "px-3": _vm.$vuetify.breakpoint.smAndDown
      }
    },
    [
      _c("v-progress-linear", {
        staticClass: "ma-0",
        attrs: { height: "8", color: "StarlingPrimary2--bg" },
        model: {
          value: _vm.progress,
          callback: function($$v) {
            _vm.progress = $$v
          },
          expression: "progress"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }