









import Vue from 'vue';
import { INavigationExtensionTabs } from '@/scripts/store/modules/navigation/types';
import TopNavExtensionProgress from '@/views/components/navigation/top-nav-extension-progress.vue';
import TopNavExtensionTabs from '@/views/components/navigation/top-nav-extension-tabs.vue';

export default Vue.extend({
  name: 'top-nav-extension',
  components: {
    TopNavExtensionProgress,
    TopNavExtensionTabs,
  },
  computed: {
    progress(): number | undefined {
      return this.$store.getters['navigation/progress'];
    },
    tabs(): INavigationExtensionTabs[] | undefined {
      return this.$store.getters['navigation/tabs'];
    },
    isTabs(): boolean {
      return !!this.tabs;
    },
    isProgress(): boolean {
      return !this.isTabs && !!this.progress;
    },
  },
});
