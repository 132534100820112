var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "", md8: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", nowrap: "" } },
        _vm._l(_vm.tabs, function(tab, index) {
          return _c(
            "v-flex",
            { key: index, attrs: { md3: "" } },
            [
              _c("router-link", {
                attrs: { to: { name: tab.route }, custom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var navigate = ref.navigate
                        var isActive = ref.isActive
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-0",
                              attrs: {
                                flat: "",
                                block: "",
                                color: isActive ? "StarlingPrimary1" : ""
                              },
                              on: { click: navigate }
                            },
                            [
                              _c(
                                "v-badge",
                                {
                                  staticClass: "badge-small",
                                  attrs: {
                                    right: "",
                                    color:
                                      _vm.showCommunityBadge && tab.badge
                                        ? ""
                                        : "transparent"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "badge",
                                        fn: function() {
                                          return [_c("span")]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("v-icon", [_vm._v(_vm._s(tab.icon))])],
                                1
                              ),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t(tab.title)) +
                                  "\n        "
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }