






















import Vue from 'vue';
import { INavigationExtensionTabs } from '@/scripts/store/modules/navigation/types';
import { ICommentStatus } from '@/scripts/store/modules/comments/types';

export default Vue.extend({
  name: 'top-nav-extension-tabs',
  computed: {
    tabs(): INavigationExtensionTabs[] | undefined {
      return this.$store.getters['navigation/tabs'];
    },
    commentStatuses(): Array<ICommentStatus> {
      return this.$store.getters['comments/getCommentsStatuses'];
    },
    showCommunityBadge(): boolean {
      return this.commentStatuses?.some(status => !status.read);
    },
  },
});
