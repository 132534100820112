






import Vue from 'vue';

export default Vue.extend({
  name: 'top-nav-extension-progress',
  computed: {
    progress(): number | undefined {
      return this.$store.getters['navigation/progress'];
    },
  },
});
